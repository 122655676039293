<template>
  <div class="row" v-if="template?.id">
    <div class="column">
      <media-instance-preview :media-instance="mediaInstance"/>
    </div>

    <div class="assets col">

      <div class="col" v-for="([name, val], k) of Object.entries(templateVars || [])" :key="k">

        <!--text!-->
        <q-input v-if="val.type === 'text'" class="col" v-model="mutate(mediaInstance).assets[name].value" :label="`Value ${name} string`" :placeholder="val.text" :hint="val.text ? val.text : undefined"/>

        <!-- Image -->
        <image-loader input :upload="uploadUrl" v-if="val.type === 'image'" v-model="mutate(mediaInstance).assets[name].value" :label="`Image ${name}`" :image="mediaInstance.assets[name]?.value ? renderer.a2u.getCdnUrl() + '/'+ mediaInstance.assets[name]?.value : undefined" width="50px" height="50px"/>

      </div>
    </div>

  </div>
</template>

<script>

import MediaInstancePreview from "@/components/MediaInstance/MediaInstancePreview.vue";
import {PromoTemplates} from "@/db/PromoTemplates";
import ImageLoader from "@/components/ImageLoader.vue";
import {renderMixins} from "app-to-up-vue-renderer/src/renderer/components/renderMixins";

export default {
  name: "MediaInstanceSettings",
  mixins: [renderMixins],
  components: {ImageLoader, MediaInstancePreview},
  props: {
    mediaInstance: {}
  },

  data: () => ({
    template: false,
    uploadUrl: ""
  }),

  created() {

    // Set upload url
    this.uploadUrl = this.renderer.a2u.getCdnUrl()?.replace("/storage/download", "")

    // Init assets
    if(!this.mediaInstance.assets) this.mutate(this.mediaInstance).assets = {}

    // Load template
    this.$watch("mediaInstance", this.loadTemplate, {
      immediate: true,
      deep: true
    });
  },

  methods: {

    /**
     * Load template
     * @return {Promise<void>}
     */
    async loadTemplate() {
      // check if code exists
      if(!this.mediaInstance?.promo_template_id) return;

      // Load promo template
      this.template = await PromoTemplates.find(this.mediaInstance?.promo_template_id)

      // Exit if no template
      if(!this.template) {
        throw new Error('Template not found')
      }

      // Init placeholders
      for(const [key, value] of Object.entries(this.templateVars)) {
        if(!this.mediaInstance.assets[key] || typeof this.mediaInstance.assets[key] !== 'object')
          this.mutate(this.mediaInstance).assets[key] = value
      }

    }
  },

  computed: {

    /**
     * Get template vars
     * @return {{}}
     */
    templateVars() {

      // Texts to translate
      const textsToReplace = {};

      // Parse template source
      const templateSource = JSON.parse(this.template.template_code || "{}")

      // Go through all elements
      const getAllVars = (node) => {
        // Extract text assets from source
        for (const e of node?.elements || []) {
          if (e.dynamic) textsToReplace[e.name || e.id] = {
            text: e.text,
            type: e.type
          }
          getAllVars(e)
        }
      }

      // Process source
      getAllVars(templateSource);

      // Return texts
      return textsToReplace
    }
  },
}

</script>
