<template>
  <div class="json-node">
    <template v-if="typeof node === 'object' && !Array.isArray(node)">
      <div v-for="(value, key) in node" :key="key" class="item">
        <label class="dg-text-weight-bold">{{ getKeyName(key) }}:</label>
        <json-node v-if="typeof value === 'object'" :node="value"/>
        <div v-else class="value">{{ value }}</div>
      </div>
    </template>
    <div v-else class="q-gutter-x-sm">
      <div v-for="(value, key) in node" :key="key" class="element">{{ value }}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: "JsonNode",
  props: {
    node: {
      type: Object,
      required: true
    }
  },
  methods: {
    /**
     * Convert key name to human readable with first letter capitalized and no underscore
     * @param key
     * @return {*}
     */
    getKeyName(key) {
      return key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
    }
  }
}

</script>


<style lang="scss">
.json-node {
  margin-left:10px;
  margin-top:5px;
  margin-bottom:5px;

  .item {
    margin-top:5px;
  }

  label {
    display: inline;
    margin-right: 5px;
  }

  .value {
    display: inline;
    line-break: auto;
    white-space: pre-line;
  }

  .element {
    display: inline-block;
    padding: 2px 5px 2px 5px;
    border-radius: 5px;
    background-color: #fa0;
  }
}
</style>
