<template>
  <div v-if="input">
    <q-field :label="label">
      <div class="q-mt-md q-mb-sm row q-gutter-x-sm" >
        <q-img v-if="imagePreview" :width="width" :height="height" :src="imagePreview" fit="cover" style="border:1px solid #ddd;"/>
        <q-btn icon="upload" flat color="primary" @click.stop="$refs.uploader.pickFiles()"/>
        <q-btn v-if="imagePreview" icon="clear" flat color="primary" @click.stop="$emit('update:modelValue', undefined)"/>
      </div>
    </q-field>
  </div>
  <div v-else class="image-loader column q-pb-xs cursor-pointer" :style="{width, height}">
    <div class="bg-primary q-pa-xs text-white text-center q-mb-sm">{{label}}</div>
    <q-img v-if="imagePreview" :src="imagePreview" fit="contain" class="col" @click="$refs.uploader.pickFiles()"/>
    <q-icon name="upload" class="absolute-center no-pointer-events" size="md"/>
  </div>
  <q-file v-model="file" ref="uploader" accept="image/*" class="hidden"/>

</template>

<script>/**
 * Component to show / upload image
 */

import {ref} from "vue";

export default {
  name: "ImageLoader",
  props: {
    modelValue: {
      default: null
    },
    label: {
      type: String,
      default: "Upload image"
    },
    image: {
    },
    width: {
      type: String,
      default: "200px"
    },
    upload: {
      type: String,
      default: ""
    },
    input: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: "200px"
    }
  },

  data: () => ({
    file: ref(null),
    imagePreview: null,
  }),

  /**
   * Set file to image
   */
  created() {

    // Set initial image
    this.imagePreview = this.image// ? this.downloadPath + this.image : "";

    // Watch for changes and emit model value
    this.$watch('file', async () => {

      // Load preview
      if (this.file) {
        const reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = () => {
          this.imagePreview = reader.result;
        }
      } else {
        this.imagePreview = "";
      }

      // Emit model value
      if(!this.upload) {
        this.$emit('update:modelValue', this.file);
      } else {

        // Upload to backend
        this.$emit('update:modelValue', await this.app.client.upload(this.file.name, this.file, this.upload));
      }
    });
  },
}

</script>

<style lang="scss">
.image-loader {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  background: #f5f5f5;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);

  .q-file {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

</style>
