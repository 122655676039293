import MediaInstancePreview from "@/components/MediaInstance/MediaInstancePreview.vue";
import MediaInstanceSettings from "@/components/MediaInstance/MediaInstanceSettings.vue";
import JSONPreview from "@/components/JSONPreview/JSONPreview.vue";

export const componentList = {
    //"test": TestComponent
    MediaInstancePreview,
    MediaInstanceSettings,
    JSONPreview
}
