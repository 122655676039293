<template>
  <atu-renderer
    v-if="source"
    :db-config="db"
    :module-id="moduleId"
    :on-init="initApp"
    :platform="platform"
    :on-destroy="destroyApp"
    :run-mode="runMode"
    :db-server-url="dbServerUrl"
    :startEvent="startEvent"
    :source="source"
    :functions="methodList"
    :storage="storage"
    :native-components="componentList"
    :debug="10"
    :plugins="plugins"
    :language="appLocale"
    :designer-components-list="designerComponentsList"
    :models="customModels"
  />
</template>

<script>

import {componentList} from "./componentList";
import db from "./db";
import {buildInMethodsList} from "@/buildInMethodsList";
import {methodList} from "@/methodList";
import {SchemaKeeper} from "app-to-up-vue-renderer/src/renderer/utils/schemaKeeper";
import {CdnManager} from './helpers/cdn/CdnManager';
import {IndexedDbFilesManager} from './helpers/idb/IndexedDbFilesManager';
import {ErrorLogger} from './helpers/error-logger/ErrorLogger';
import {moduleConfig} from "./module-config";
import {designerComponentsList} from '@designerComponentsList';

export default {
  name: 'TestApp',

  mixins: [moduleConfig.mixin],

  setup() {
    return {
      designerComponentsList,
    }
  },

  computed: {
    methodList() {
      return {
        ...buildInMethodsList,
        ...methodList,
      }
    },
    db() {
      return db
    },
    componentList() {
      return componentList
    },
  },
  data: () => ({
    source: false,
    moduleId: false,
    storage: false,
    startEvent: "start",
    runMode: "debug",
    platform: "web",
    dbServerUrl: "",
    appLocale: null,
    plugins: {
      CdnManager,
      IndexedDbFilesManager,
      ErrorLogger,
      ...moduleConfig.plugins
    },
    customModels: moduleConfig.customDbModels,
  }),
  async created() {

    // Get segments
    //const [, mId, mode] = window.location.pathname.split('/')

    // Store run mode
    this.runMode = process.env.VUE_APP_RUN_MODE

    // Check for stage mode and hash
    if(this.runMode !== "release" && window.location.hash) this.runMode = window.location.hash.slice(1)

    // Set db url
    this.dbServerUrl = process.env.VUE_APP_DB_URL

    // Get appId from hash
    this.moduleId = parseInt(process.env.VUE_APP_MODULE_ID);

    // Set start event
    this.startEvent = new URL(window.location?.href)?.searchParams?.get("start") || "start"

    // Log start event
    console.log("Start event: ", this.startEvent);

    // Create schema keeper
    const schemaKeeper = new SchemaKeeper(this.app, this.moduleId, "web", this.runMode, this.loadSchema())

    try {
      this.appLocale = navigator.language.split('-')[0].toLowerCase() || 'en';
    } catch (e) {
      console.error('Error getting locale', e);
    }

    // Get source
    this.source = await schemaKeeper.getSource()
  },

  methods: {

    /**
     * Init app
     */
    async initApp(a2u) {

      // Get device
      const device = a2u.getDevice()

      // Log device
      console.log("Device: ", device);

      // Check for iap config and init iap manager
      if (a2u?.source?.iap && a2u?.source?.iap?.products) {
        // Get stripe module
        const stripeModule = Object.values(a2u?.source?.modules || []).find((m) => m.stripeEnable);

        // Init plugin
        await device.getPluginInstance("IAPManager")?.init({
          ...a2u.source.iap,
          client: this.app.client,
          endpoint: stripeModule?.endpointUrl,
          stripe: {
            ...(a2u.source.stripe || {})
          },
          runMode: this.runMode,
          device,
          dialogPlugin: this.$q.dialog,
        });
      }

      // Check for marketing pixels and init marketing pixels plugin
      if (this.runMode === 'release' && (a2u?.source?.marketingPixels || device.getPlugin("AnalyticsProvider"))) {
        device.getPluginInstance('MarketingPixelsPlugin')?.init({
          ...(a2u.source.marketingPixels || {}),
          firebase: device.getPlugin("AnalyticsProvider") || null,
        });
      }

      // Check for cdn config and init cdn manager
      if (a2u?.source?.cdnServer?.endpoint) {
        await device.getPluginInstance('CdnManager')?.init({
          runMode: a2u.runMode,
          platform: a2u.platform,
          cdnServers: a2u.getCdnUrl() || a2u.source.cdnServer.endpoint,
        });
      }

      // Init modules on app init
      await this.initModulesOnAppInit(a2u);

      // Post app init
      /*PostInitApp*/

    },

    /**
     * Destroy app
     * @param a2u
     */
    destroyApp() {

    },

    /**
     * Loads the schema based on the current run mode and start event.
     * @returns {Object} The loaded schema.
     */
    loadSchema() {
      try {
        if (this.runMode === 'release') {
          return require(`@/assets/storage/${this.startEvent}.schema.a2u`);
        } else {
          return require("@/assets/storage/schema.a2u");
        }
      } catch (e) {
        console.error('Error loading schema', e);

        return require("@/assets/storage/schema.a2u");
      }
    },
  }
}
</script>
